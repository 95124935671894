@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor-default{
  cursor: url('/src/assets/default.png'), auto !important;
}
.cursor-tilt-start{
  cursor: url('/src/assets/tilt-start.png'), auto !important;
}
.cursor-tilt-end{
  cursor: url('/src/assets/tilt-end.png'), auto !important;
}
.cursor-arc{
  cursor: url('/src/assets/arc.png'), auto !important;
}
.cursor-resize-horizontal{
  cursor: url('/src/assets/resize-horizontal.png'), auto !important;
}
.cursor-vector{
  cursor: url('/src/assets/vector.png'), auto !important;
}
@import "flowbite"; 